import { createSlice } from "@reduxjs/toolkit";
export const systemSlice = createSlice({
  name: "system",
  initialState: {
    carousel: [],
    carouselPopup: [],
    systemImage: {},
    bankDepositAccount: [],
    jackpot: {},
    bankList: [],
  },
  reducers: {
    setCarousel: (state, action) => {
      state.carousel = action.payload;
    },
    setCarouselPopup: (state, action) => {
      state.carouselPopup = action.payload;
    },
    setSystemImage: (state, action) => {
      state.systemImage = action.payload;
    },
    setDepositBankAccount: (state, action) => {
      state.bankDepositAccount = action.payload;
    },
    setJackpot: (state, action) => {
      state.jackpot = action.payload;
    },
    setBankList: (state, action) => {
      state.bankList = action.payload;
    },
  },
});

export const {
  setCarousel,
  setCarouselPopup,
  setSystemImage,
  setDepositBankAccount,
  setJackpot,
  setBankList,
} = systemSlice.actions;
export const getCarousel = (state) => state.system.carousel;
export const getCarouselPopup = (state) => state.system.carouselPopup;
export const getSystemImage = (state) => state.system.systemImage;
export const getBankDepositAccount = (state) => state.system.bankDepositAccount;
export const getJackpot = (state) => state.system.jackpot;
export const getBankList = (state) => state.system.bankList;

export default systemSlice.reducer;
