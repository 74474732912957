import React, { useState } from "react";
import { Grid, Backdrop, CircularProgress } from "@mui/material";
import logo from "src/assets/logo/logo-default.png";
import { useTranslation } from "react-i18next";

export default function ModalLoadingClock({ isOpen, imglogo }) {
  const { t } = useTranslation();
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isOpen}
      >
        <div className="container_loader">
          <div className="clock"></div>
          <p style={{ marginTop: "7rem" }}>Loading...</p>
        </div>
      </Backdrop>
    </div>
  );
}
