import React, { Suspense } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { persistor, store } from "src/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import Loading from "src/components/modal/ModalLoading";
import LoadingฺV2 from "src/components/modal/ModalLoadingClock";
import "./i18n";

const TheContent = React.lazy(() => import("src/components/TheContent"));

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          {window.env.REACT_APP_NAME} -
          {window.env.REACT_APP_NAME === "BB98BET"
            ? " เว็บที่ดีที่สุดสุดยอดเว็บคาสิโนออนไลน์ บาคาร่า สล็อต แห่งระบบที่ที่สุด"
            : " เว็บหวยออนไลน์ ที่ดีที่สุดสุดยอดเว็บคาสิโนออนไลน์ ที่สุดแห่งระบบที่ที่สุด"}
        </title>
        <meta
          name="description"
          content={`${
            window.env.REACT_APP_NAME === "BB98BET"
              ? `${window.env.REACT_APP_NAME} - คาสิโนออนไลน์ บาคาร่า สล็อต อันดับหนึ่งของไทย ฝาก-ถอน 30 วินาที`
              : `${window.env.REACT_APP_NAME} - แทงหวย, ตรวจหวย, ผลหวย, หวยฮานอย, หวยลาว, หวยรัฐบาล`
          }`}
        />
        <meta
          property="og:description"
          content={`${
            window.env.REACT_APP_NAME === "BB98BET"
              ? "คาสิโนออนไลน์ บาคาร่า สล็อต อันดับหนึ่งของไทย ฝาก-ถอน 30 วินาที"
              : "สมัครเล่นหวย หวยใต้ดิน หวยออนไลน์ แทงหวยลาว หวยฮานอย หวยรัฐบาล จ่ายสูงที่สุด พร้อมผลหวยทุกชนิดและเลขเด็ดบริการฟรี ตลอด 24 ช.ม. การเงินมั่นคงอันดับ 1 ในไทย"
          }`}
        />
      </Helmet>
      <LoadingฺV2 />
      <Suspense fallback={null}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <TheContent />
          </PersistGate>
        </Provider>
      </Suspense>
    </HelmetProvider>
  );
}

export default App;
